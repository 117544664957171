import React from 'react';

import { list } from './social-list.module.scss';
import { ISocialLink } from '../../models/social-link.model';
import { TButtonProps } from '../atoms/button';

import SocialLink from '../atoms/social-link';

interface ISocialListProps {
    className?: string;
    iconJustify?: TButtonProps['justify'];
    socialLinks: ISocialLink[];
}

const SocialList: React.FC<ISocialListProps> = ({ className = '', socialLinks, iconJustify }) => {
    return (
        <ul className={`${list} ${className}`}>
            {socialLinks.map((socialLink) => {
                return (
                    <li key={socialLink.name}>
                        <SocialLink socialLink={socialLink} iconJustify={iconJustify} />
                    </li>
                );
            })}
        </ul>
    );
};

export default SocialList;
