import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    innerContainer,
    open,
    navigation,
    list,
    address,
    socials,
} from './navigation.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { pageContextsBase } from '../../config/page-contexts-base';
import { socialLinks } from '../../config/social-links';

import SocialList from './social-list';
import Semicircle from '../atoms/semicircle';
import NavLink from '../atoms/nav-link';

interface INavigationProps {
    className?: string;
    isOpen?: boolean;
}

const pageContextsForNav = Object.values(pageContextsBase)
    .filter((page) => page.isInNavigation && page.sequence !== undefined)
    .sort((a, b) => (a.sequence || 0) - (b.sequence || 0));

const Navigation: React.FC<INavigationProps> = ({ className = '', isOpen = false }) => {
    const { t } = useI18next();
    return (
        <div className={`${container} ${className} ${isOpen ? open : ''}`}>
            <div className={`${innerContainer} ${grid}`}>
                <nav className={navigation}>
                    <ul className={list}>
                        {pageContextsForNav.map((page) => {
                            return (
                                <li key={`nav-link-${page.slug}`}>
                                    <NavLink navLink={page} />
                                </li>
                            );
                        })}
                    </ul>
                    <Semicircle orientation="left" color="random" />
                </nav>
                <address className={address}>
                    <a href={`mailto:${t('address.mail')}`}>{t('address.mail')}</a>
                    <p>
                        <a href={`tel:${t('address.phone.one')}`}>{t('address.phone.one')}</a> /
                        <a href={`tel:${t('address.phone.two')}`}>{t('address.phone.two')}</a>
                    </p>
                    <p>
                        {t('address.street')}, {t('address.city')}
                    </p>
                </address>
                <SocialList
                    className={socials}
                    socialLinks={socialLinks}
                    iconJustify="flex-start"
                />
            </div>
        </div>
    );
};

export default Navigation;
