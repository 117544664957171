import React from 'react';

import { link, icon } from './social-link.module.scss';
import { ISocialLink, TSocialName } from '../../models/social-link.model';
import FacebookIcon from '../../assets/images/svg/facebook.svg';
import BehanceIcon from '../../assets/images/svg/behance.svg';
import InstagramIcon from '../../assets/images/svg/instagram.svg';
import LinkedinIcon from '../../assets/images/svg/linkedin.svg';

import Button, { TButtonProps } from './button';

interface ISocialLinkProps {
    className?: string;
    iconJustify?: TButtonProps['justify'];
    socialLink: ISocialLink;
}

const SocialLink: React.FC<ISocialLinkProps> = ({ className = '', socialLink, iconJustify }) => {
    const { name, url } = socialLink;
    const Icon = icons[name];
    return (
        <Button
            className={`${link} ${className}`}
            justify={iconJustify}
            as="externalLink"
            aria-label={`alterpage ${name}`}
            title={`alterpage ${name}`}
            href={url}
            rel="noreferrer noopener"
            kind="icon"
            target="_blank"
        >
            <Icon className={icon} />
        </Button>
    );
};

const icons: Record<TSocialName, React.SVGFactory> = {
    instagram: InstagramIcon,
    behance: BehanceIcon,
    facebook: FacebookIcon,
    linkedin: LinkedinIcon,
};

export default SocialLink;
