import React from 'react';

import {
    container,
    semicircle,
    dark,
    light,
    arrowLight,
    arrowCircle,
} from './section-wrapper.module.scss';

import Semicircle, { TAccentColor, TSemicircleOrientation } from '../atoms/semicircle';
import ButtonScrollDown from './button-scroll-down';
import { TScrollTo } from '../../models/scroll-to.model';

interface ISectionWrapperProps {
    className?: string;
    children?: React.ReactNode;
    color?: TAccentColor | 'random';
    orientation?: TSemicircleOrientation;
    bgColor?: 'light' | 'dark';
    isArrowDown?: boolean;
    semiCircleClassName?: string;
    scrollToSection?: TScrollTo;
}

const SectionWrapper: React.FC<ISectionWrapperProps> = ({
    className = '',
    children,
    color,
    orientation,
    bgColor = 'dark',
    isArrowDown = true,
    semiCircleClassName,
    scrollToSection,
}) => {
    return (
        <div className={`${className} ${container} ${bgColor === 'dark' ? dark : light} `}>
            {children}
            <Semicircle
                orientation={orientation}
                color={color}
                className={`${semicircle} ${semiCircleClassName}`}
                containerType={'half'}
            />
            {isArrowDown && (
                <ButtonScrollDown
                    className={`${bgColor === 'light' ? arrowLight : ''} ${arrowCircle}`}
                    scrollToSection={scrollToSection}
                />
            )}
        </div>
    );
};

export default SectionWrapper;
