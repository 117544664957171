import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    title,
    subtitle,
    container,
    innerContainer,
    form,
    video,
    content,
    bottomText,
    semicircle,
} from './hero.module.scss';
import { grid } from '../../styles/grid.module.scss';

import SectionWrapper from '../molecules/section-wrapper';
import VideoHero from '../molecules/video-hero';
import FormHero from '../molecules/form-hero';

interface IHeroProps {
    className?: string;
}

const Hero: React.FC<IHeroProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <SectionWrapper
            className={`${className} ${grid} ${container}`}
            orientation="left"
            color="pink"
            semiCircleClassName={semicircle}
            scrollToSection={'wall-of-fame'}
        >
            <div className={`${innerContainer}`}>
                <h1 className={title}>{t('lp1.hero.title')}</h1>
                <h2 className={subtitle}>{t('lp1.hero.subtitle')}</h2>

                <div className={content}>
                    <FormHero className={form} />
                    <VideoHero className={video} />
                </div>

                <p className={bottomText}>
                    {t('lp1.hero.bottom.text1')} <br /> {t('lp1.hero.bottom.text2')}{' '}
                    <a href={t('lp1.alterpage.url')} target="_blank" rel="noreferrer">
                        {t('lp1.alterpage.url')}
                    </a>
                </p>
            </div>
        </SectionWrapper>
    );
};

export default Hero;
