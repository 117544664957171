import { graphql, useStaticQuery } from 'gatsby';
import { ModuleConfig } from '@alterpage/cookies-widget';

import { IFiles } from '../models/cookie-content-file.model';
import { getNodes } from '../utils/get-nodes';

export default function useCookiesTranslations(defaultConfig: ModuleConfig) {
    const { allFile } = useStaticQuery<IFiles>(query);

    const cookiesContent = getNodes(allFile).reduce<ModuleConfig['content']>(
        (acc, { relativeDirectory, name, childMarkdownRemark }) => {
            if (acc) {
                if (!acc[relativeDirectory]) {
                    acc[relativeDirectory] = {};
                }

                acc[relativeDirectory] = {
                    ...acc[relativeDirectory],
                    [name]: childMarkdownRemark.rawMarkdownBody,
                };
            }

            return acc;
        },
        {}
    );

    return {
        ...defaultConfig,
        content: {
            ...defaultConfig.content,
            ...(cookiesContent
                ? {
                    ...Object.keys(cookiesContent).reduce<ModuleConfig['content']>((acc, key) => {
                        const defaultConfigContent = defaultConfig.content
                            ? defaultConfig.content[key]
                            : {};
                        if (acc) {
                            acc[key] = {
                                views: cookiesContent[key] as Record<string, string>,
                                ...defaultConfigContent,
                            };
                        }
                        return acc;
                    }, {}),
                }
                : {}),
        },
    };
}

const query = graphql`
    query {
        allFile(filter: { sourceInstanceName: { eq: "cookies" }, extension: { eq: "md" } }) {
            edges {
                node {
                    name
                    relativeDirectory
                    childMarkdownRemark {
                        rawMarkdownBody
                    }
                }
            }
        }
    }
`;
