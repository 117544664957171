import React from 'react';
import SectionWrapper from '../molecules/section-wrapper';

import {
    semicircle,
    container,
    innerContainer,
    titleTop,
    titleBottom,
    details,
    text,
    textBottom,
    circle,
} from './contact.module.scss';
import { grid } from '../../styles/grid.module.scss';

import ContactDetails from '../molecules/contact-details';
import Semicircle from '../atoms/semicircle';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Markdown from '../hoc/markdown';

interface IContactProps {
    className?: string;
}

const Faq: React.FC<IContactProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <SectionWrapper
            className={`${className} ${grid} ${container}`}
            color={'pink'}
            orientation={'left'}
            semiCircleClassName={semicircle}
            isArrowDown={false}
        >
            <div className={innerContainer} id="contact">
                <Semicircle
                    containerType={'half'}
                    color={'blue'}
                    orientation={'top'}
                    className={circle}
                />
                <Semicircle
                    containerType={'half'}
                    color={'yellow'}
                    orientation={'top'}
                    className={circle}
                />
                <Semicircle
                    containerType={'half'}
                    color={'pink'}
                    orientation={'top'}
                    className={circle}
                />
                <Semicircle
                    containerType={'half'}
                    color={'purple'}
                    orientation={'top'}
                    className={circle}
                />
                <Semicircle
                    containerType={'half'}
                    color={'blue'}
                    orientation={'top'}
                    className={circle}
                />
                <Semicircle
                    containerType={'half'}
                    color={'yellow'}
                    orientation={'top'}
                    className={circle}
                />

                <h4 className={titleTop}>{t('lp1.contact.title')}</h4>
                <ContactDetails className={details} />
                <h4 className={titleBottom}>{t('lp1.contact.title.bottom')}</h4>
                <p className={text}>{t('lp1.contact.text')}</p>
                <Markdown className={textBottom}>{t('lp1.contact.text.bottom')}</Markdown>
            </div>
        </SectionWrapper>
    );
};

export default Faq;
