import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { button, lines, line, one, two } from './close-button.module.scss';

import Button, { TButtonProps } from './button';

const CloseButton: React.FC<TButtonProps> = ({ className, ...rest }) => {
    const { t } = useI18next();
    return (
        <Button
            aria-label={t('button.close')}
            {...rest}
            kind="icon"
            className={`${button} ${className}`}
        >
            <div className={lines}>
                <span className={`${line} ${one}`} />
                <span className={`${line} ${two}`} />
            </div>
        </Button>
    );
};

export default CloseButton;
