import axios from 'axios';
import { config } from '../config';

interface IFormData {
    name: string;
    email: string;
    content: string;
    form: string;
}

export function postForm(data: IFormData): Promise<any> {
    return axios.post(config.endpoints.form.contact, data, { withCredentials: true });
}
