import React from 'react';

import { link, text } from './nav-link.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { INavLink } from '../../models/nav-link.model';
import { useTranslation } from '../../hooks/use-translation';

interface INavLinkProps {
    className?: string;
    navLink: INavLink;
}

const NavLink: React.FC<INavLinkProps> = ({ className = '', navLink }) => {
    const { label } = useTranslation(navLink, translationKeys.navLink);
    return (
        <>
            <a href={navLink.url} className={link} target="_blank" rel="noreferrer">
                <span className={text}>{label}</span>
            </a>
        </>
    );
};

export default NavLink;
