import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, line, middle, top, bottom, text } from './hamburger.module.scss';

interface IHamburgerProps {
    className?: string;
    onClick?(): void;
    isOpen?: boolean;
}

const Hamburger: React.FC<IHamburgerProps> = ({ className = '', onClick, isOpen }) => {
    const { t } = useI18next();

    const handleClick = () => {
        if (typeof onClick !== 'function') return;
        onClick();
    };

    return (
        <button className={`${container} ${className}`} onClick={handleClick}>
            <span className={text}>{t('hamburger.text')}</span>
            <span className={`${line} ${top}`} />
            <span className={`${line} ${middle}`} />
            <span className={`${line} ${bottom}`} />
        </button>
    );
};

export default Hamburger;
