import React from 'react';

import { title, videoContainer, video, text, container } from './video-hero.module.scss';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import VideoSrc from '../../assets/videos/hero_video.mp4';
import Thumbnail from '../../assets/images/thumbnail-video.png';

interface IVideoHeroProps {
    className?: string;
}

const VideoHero: React.FC<IVideoHeroProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${className} ${container}`}>
            <h4 className={title}>{t('lp1.hero.video.title')}</h4>
            <div className={videoContainer}>
                <video controls className={video} poster={Thumbnail}>
                    <source src={VideoSrc} type="video/mp4" />
                </video>
            </div>
            <p className={text}>{t('lp1.hero.video.text')}</p>
        </div>
    );
};

export default VideoHero;
