import { ISocialLink } from '../models/social-link.model';

export const socialLinks: ISocialLink[] = [
    {
        name: 'instagram',
        url: 'https://www.instagram.com/alterpage/',
    },
    {
        name: 'behance',
        url: 'https://www.behance.net/alterpage',
    },
    {
        name: 'facebook',
        url: 'https://www.facebook.com/alterpagepl/',
    },
    {
        name: 'linkedin',
        url: 'https://www.linkedin.com/company/alterpage',
    },
];
