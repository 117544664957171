import React, { useState } from 'react';
import SectionWrapper from '../molecules/section-wrapper';
import { motion } from 'framer-motion';

import {
    semicircle,
    container,
    innerContainer,
    title,
    listFirst,
    listSecond,
    buttonFaq,
} from './faq.module.scss';
import { grid } from '../../styles/grid.module.scss';
import AccordionList from '../molecules/accordion-list';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

interface IFaqProps {
    className?: string;
}

const getItemsDataFirst = (t: ReturnType<typeof useI18next>['t']) => {
    return new Array(5).fill(0).map((item, index) => ({
        title: t(`lp1.faq.question.${index + 1}`),
        content: t(`lp1.faq.answer.${index + 1}`),
    }));
};

const getItemsDataSecond = (t: ReturnType<typeof useI18next>['t']) => {
    return new Array(23).fill(0).map((item, index) => ({
        title: t(`lp1.faq.question.${index + 6}`),
        content: t(`lp1.faq.answer.${index + 6}`),
    }));
};

const Faq: React.FC<IFaqProps> = ({ className = '' }) => {
    const { t } = useI18next();

    const itemsDataFirst = getItemsDataFirst(t);
    const itemsDataSecond = getItemsDataSecond(t);

    const [isActive, setIsActive] = useState(false);

    return (
        <SectionWrapper
            scrollToSection="contact"
            className={`${className} ${grid} ${container}`}
            color={'blue'}
            orientation={'right'}
            semiCircleClassName={semicircle}
            bgColor={'light'}
        >
            <div className={innerContainer} id="faq">
                <h4 className={title}>{t('lp1.faq.title')}</h4>
                <AccordionList items={itemsDataFirst} className={listFirst} />

                <motion.div
                    style={{ overflow: isActive ? 'visible' : 'hidden' }}
                    initial={{ height: 0 }}
                    animate={{ height: isActive ? 'auto' : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <AccordionList items={itemsDataSecond} className={listSecond} />
                </motion.div>

                {!isActive && (
                    <button onClick={() => setIsActive(!isActive)} className={buttonFaq}>
                        <span>zobacz więcej</span>
                        <span>zobacz więcej</span>
                    </button>
                )}
            </div>
        </SectionWrapper>
    );
};

export default Faq;
