// extracted by mini-css-extract-plugin
export var arrowRight = "wall-of-fame-module--arrow-right--Cgx3D";
export var button = "wall-of-fame-module--button--Pwt0-";
export var buttonOpinion = "wall-of-fame-module--button-opinion--cGQsB";
export var container = "wall-of-fame-module--container--Q89ta";
export var containerOpinion = "wall-of-fame-module--container-opinion--G7PDI";
export var innerContainer = "wall-of-fame-module--inner-container--BdETP";
export var link = "wall-of-fame-module--link--S0jp2";
export var opinions = "wall-of-fame-module--opinions--F5r3d";
export var semicircle = "wall-of-fame-module--semicircle--jwana";
export var smallText = "wall-of-fame-module--small-text---UElK";
export var smallTitle = "wall-of-fame-module--small-title--CA784";
export var title = "wall-of-fame-module--title--c+KKR";
export var titleText = "wall-of-fame-module--title-text--lEe2F";
export var titleTextFirst = "wall-of-fame-module--title-text-first--70Uvq";
export var titleTextSecond = "wall-of-fame-module--title-text-second--QLTb4";
export var titleTextThird = "wall-of-fame-module--title-text-third--S3YS3";
export var type = "wall-of-fame-module--type--8L4fx";