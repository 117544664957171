import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import SectionWrapper from '../molecules/section-wrapper';

import ArrowRight from '../../assets/images/svg/arrow-right.svg';

import {
    container,
    innerContainer,
    title,
    opinions,
    smallTitle,
    smallText,
    button,
    arrowRight,
    containerOpinion,
    titleText,
    type,
    buttonOpinion,
    link,
    semicircle,
    titleTextFirst,
    titleTextSecond,
    titleTextThird,
} from './wall-of-fame.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

interface IWallOfFameProps {
    className?: string;
}

const WallOfFame: React.FC<IWallOfFameProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <SectionWrapper
            className={`${className} ${grid} ${container}`}
            orientation="right"
            color="yellow"
            bgColor="light"
            semiCircleClassName={semicircle}
            scrollToSection={'cooperation'}
        >
            <div className={innerContainer} id="wall-of-fame">
                <h4 className={title}>{t('lp1.wall.title')}</h4>
                <div className={opinions}>
                    <div className={link}>
                        <div className={` ${className} ${containerOpinion} `}>
                            <StaticImage src={'../../assets/images/loreal-logo.png'} alt="" />
                            <StaticImage src={'../../assets/images/loreal-banner.jpg'} alt="" />
                            <p className={`${titleText} ${titleTextFirst}`}>
                                {t('lp1.wall.opinion1')}
                            </p>
                            <p className={type}>{t('lp1.wall.opinion1.text')}</p>
                        </div>
                        <a
                            href={'https://alterpage.pl/uploads/pdf/p3/5eb402ffd18ff.pdf'}
                            target="_blank"
                            className={buttonOpinion}
                            rel="noreferrer"
                        >
                            <span>{t('lp.wall.opinion.button')}</span>
                            <span>{t('lp.wall.opinion.button')}</span>
                        </a>
                    </div>

                    <div className={link}>
                        <div className={` ${className} ${containerOpinion} `}>
                            <StaticImage src={'../../assets/images/cedc-logo.png'} alt="" />
                            <StaticImage src={'../../assets/images/cedc-banner.png'} alt="" />
                            <p className={`${titleText} ${titleTextSecond}`}>
                                {t('lp1.wall.opinion2')}
                            </p>
                            <p className={type}>{t('lp1.wall.opinion2.text')}</p>
                        </div>
                        <a
                            href={'https://alterpage.pl/uploads/pdf/2z/5eb4028b134d3.pdf'}
                            target="_blank"
                            className={buttonOpinion}
                            rel="noreferrer"
                        >
                            <span>{t('lp.wall.opinion.button')}</span>
                            <span>{t('lp.wall.opinion.button')}</span>
                        </a>
                    </div>

                    <div className={link}>
                        <div className={` ${className} ${containerOpinion} `}>
                            <StaticImage src={'../../assets/images/lot-logo.png'} alt="" />
                            <StaticImage src={'../../assets/images/lot-banner.png'} alt="" />
                            <p className={`${titleText} ${titleTextThird}`}>
                                {t('lp1.wall.opinion3')}
                            </p>
                            <p className={type}>{t('lp1.wall.opinion3.text')}</p>
                        </div>
                        <a
                            href={'https://alterpage.pl/uploads/pdf/05/5eb4033623ae9.pdf'}
                            target="_blank"
                            className={buttonOpinion}
                            rel="noreferrer"
                        >
                            <span>{t('lp.wall.opinion.button')}</span>
                            <span>{t('lp.wall.opinion.button')}</span>
                        </a>
                    </div>
                </div>
                <h5 className={smallTitle}>{t('lp1.wall.small.title')}</h5>
                <p className={smallText}>
                    {t('lp1.wall.text.bottom.1')} <br />
                    {t('lp1.wall.text.bottom.2')}
                </p>
                <a
                    href={'https://alterpage.pl/portfolio'}
                    className={button}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('lp1.wall.button')} <ArrowRight className={arrowRight} />
                </a>
            </div>
        </SectionWrapper>
    );
};

export default WallOfFame;
