import React from 'react';
import { graphql } from 'gatsby';

import { layout, hero, wallOfFame as wall, cooperation, faq, contact } from './index.module.scss';
import { grid } from '../styles/grid.module.scss';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import WallOfFame from '../components/organisms/wall-of-fame';
import Cooperation from '../components/organisms/cooperation';
import Faq from '../components/organisms/faq';
import Contact from '../components/organisms/contact';

const IndexPage = () => {
    return (
        <MainLayout className={`${layout} ${grid}`}>
            <Hero className={hero} />
            <WallOfFame className={wall} />
            <Cooperation className={cooperation} />
            <Faq className={faq} />
            <Contact className={contact} />
        </MainLayout>
    );
};

export const query = graphql`
    query IndexPage($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default IndexPage;
