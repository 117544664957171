import { CookiesConfig, ModuleConfig } from '@alterpage/cookies-widget';

export const cookies: CookiesConfig = {
    pl: [
        {
            group: 'necessary',
            title: 'AP_ALLOW',
            description: 'Jest to cookie, który zapamiętuje ustawienia użytkownika.',
        },
        {
            group: 'necessary',
            title: 'AP_ANALYTICS',
            description: 'Jest to cookie, który zapamiętuje wyrażenie zgody na analityczne pliki cookies.',
        },
        {
            group: 'necessary',
            title: 'AP_ADS',
            description: 'Jest to cookie, który zapamiętuje wyrażenie zgody na marketingowe pliki cookies.',
        },
        {
            group: 'analytics',
            title: '_ga, _gat',
            description: 'Umożliwiają śledzenie ruchów na stronie.',
        },
        {
            group: 'ads',
            title: '_fbp, usida, datr, li_gc, lidc, lang, bcookie',
            description: 'Zewnętrzne pliki cookies służące do dostosowania działań marketingowych.',
        },
    ],
};

export const cookiesConfig: ModuleConfig = {
    cookies: {
        use: ['analytics', 'ads'],
    },
    privacyPolicyLink: {
        pl: 'https://alterpage.pl/media/pdf/polityka_prywatnosci.pdf',
    },
    content: {
        pl: {
            listItems: {
                ads: 'Marketingowe pliki cookies',
            },
        },
    },
};
