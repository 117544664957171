import React, {useEffect, useRef} from 'react';
import { motion, Variants } from 'framer-motion';
import { CookiesBar, CookiesHelpers } from '@alterpage/cookies-widget';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useLocation } from '@reach/router';
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { layout, changer, changerText, header, main, logo, cookiesBar, cookiesStyles, cookiesLogo } from './main-layout.module.scss';
import { cookies, cookiesConfig as defaultCookiesConfig } from '../config/cookies';
import Logo from '../assets/images/svg/logo.svg';
import { ISEOProps } from '../components/seo';
import useCookiesTranslations from "../hooks/use-cookies-translations";

import SEO from '../components/seo';
import Header from '../components/organisms/header';

interface IMainLayoutProps {
    className?: string;
    children?: React.ReactNode;
    SEOProps?: ISEOProps;
}

const MainLayout: React.FC<IMainLayoutProps> = ({ className = '', children, SEOProps }) => {
    const { language } = useI18next();
    const location = useLocation();
    const cookiesConfig = useCookiesTranslations(defaultCookiesConfig);
    const cookiesRef = useRef<CookiesHelpers>();

    useEffect(() => {
        const now = new Date().getTime();
        const expirationTime = now + 1000*36000;
        const expirationDate = new Date(expirationTime);
        document.cookie=`AP_ADS_AP=true;expires=${expirationDate};path=/`;
        initializeAndTrack(location);
    }, []);

    return (
        <>
            <SEO {...SEOProps} />
            <div className={layout}>
                <Header className={header} />
                <main className={className}>{children}</main>
                <CookiesBar
                    className={cookiesBar}
                    ref={cookiesRef}
                    Logo={<Logo className={cookiesLogo} />}
                    cookies={cookies}
                    language={language}
                    config={cookiesConfig}
                    commonClassName={cookiesStyles}
                />
                <motion.div
                    className={changer}
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <div className={changerText}>
                        <Logo className={logo} />
                    </div>
                </motion.div>
            </div>
        </>
    );
};

const pageVariants: Variants = {
    initial: {
        clipPath: 'circle(200% at 50% 0%)',
        transition: {
            duration: 0.5,
            ease: 'linear',
        },
    },
    animate: {
        clipPath: 'circle(25% at 50% -50%)',
        transition: {
            duration: 0.75,
            delay: 0.1,
            ease: 'linear',
        },
    },
    exit: {
        clipPath: ['circle(25% at 50% 150%)', 'circle(200% at 50% 100%)'],
        transition: {
            duration: 0.5,
            delay: 0.1,
            ease: 'linear',
        },
    },
};

export default MainLayout;
