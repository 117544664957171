import { IPageContextBase } from '../models/page-context.model';

export type TStaticPageContextKey =
    | 'home'
    | 'about'
    | 'whatWeDo'
    | 'portfolio'
    | 'howDoWeWork'
    | 'apropos'
    | 'career'
    | 'contact';

export const pageContextsBase: Record<TStaticPageContextKey, IPageContextBase> = {
    home: {
        slug: '/',
        translations: {
            pl: {
                slug: '/',
                label: 'Alterpage Agencja Interaktywna',
            },
        },
    },
    about: {
        slug: 'o-nas',
        url: 'https://alterpage.pl/o-nas',
        isInNavigation: true,
        sequence: 1,
        translations: {
            pl: {
                slug: 'o-nas',
                label: 'O nas',
            },
        },
    },
    whatWeDo: {
        slug: 'co-robimy',
        url: 'https://alterpage.pl/co-robimy',
        isInNavigation: true,
        sequence: 2,
        translations: {
            pl: {
                slug: 'co-robimy',
                label: 'Co robimy',
            },
        },
    },
    portfolio: {
        slug: 'portfolio',
        url: 'https://alterpage.pl/portfolio',
        isInNavigation: true,
        sequence: 3,
        translations: {
            pl: {
                slug: 'portfolio',
                label: 'Portfolio',
            },
        },
    },
    howDoWeWork: {
        slug: 'jak-dzialamy',
        url: 'https://alterpage.pl/jak-dzialamy',
        isInNavigation: true,
        sequence: 4,
        translations: {
            pl: {
                slug: 'jak-dzialamy',
                label: 'Jak działamy',
            },
        },
    },
    apropos: {
        slug: 'apropos',
        url: 'https://alterpage.pl/apropos',
        isInNavigation: true,
        sequence: 5,
        translations: {
            pl: {
                slug: 'apropos',
                label: 'Apropos',
            },
        },
    },
    career: {
        slug: 'kariera',
        url: 'https://alterpage.pl/kariera',
        isInNavigation: true,
        sequence: 6,
        translations: {
            pl: {
                slug: 'kariera',
                label: 'Kariera',
            },
        },
    },
    contact: {
        slug: 'kontakt',
        url: 'https://alterpage.pl/kontakt',
        isInNavigation: true,
        sequence: 7,
        translations: {
            pl: {
                slug: 'kontakt',
                label: 'Kontakt',
            },
        },
    },
};
