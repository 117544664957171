// extracted by mini-css-extract-plugin
export var blue = "semicircle-module--blue--kSpZ8";
export var bottom = "semicircle-module--bottom--gXczZ";
export var circle = "semicircle-module--circle--u6NRo";
export var circleBox = "semicircle-module--circle-box--oxnBL";
export var container = "semicircle-module--container--d2YcJ";
export var full = "semicircle-module--full--eVoL9";
export var half = "semicircle-module--half--iMrqv";
export var left = "semicircle-module--left--pZF47";
export var pink = "semicircle-module--pink--bjbnn";
export var purple = "semicircle-module--purple--JtQcf";
export var ratio = "semicircle-module--ratio--SOjw4";
export var right = "semicircle-module--right--3Sdpr";
export var top = "semicircle-module--top--ef0vr";
export var yellow = "semicircle-module--yellow--z2jm7";