import { ISiteMetadataTranslation } from '../models/site-metadata.model';
import { IPageContextBaseTranslation } from '../models/page-context.model';
import { INavLinkTranslation } from '../models/nav-link.model';

interface ITranslationKeys {
    navLink: Array<keyof INavLinkTranslation>;
    pageContextBase: Array<keyof IPageContextBaseTranslation>;
    siteMetadata: Array<keyof ISiteMetadataTranslation>;
}

export const translationKeys: ITranslationKeys = {
    navLink: ['label'],
    pageContextBase: ['slug', 'label'],
    siteMetadata: ['defaultTitle', 'defaultDescription'],
};
