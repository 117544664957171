// extracted by mini-css-extract-plugin
export var button = "form-hero-module--button--N7-yy";
export var error = "form-hero-module--error--PcZ4C";
export var errorGlobal = "form-hero-module--error-global--lZHdv";
export var errorStatus = "form-hero-module--error-status---JEds";
export var form = "form-hero-module--form--AXbGE";
export var formLoading = "form-hero-module--form-loading--GukWh";
export var formSuccess = "form-hero-module--form-success--KkIMn";
export var innerForm = "form-hero-module--inner-form--wq0ZA";
export var input = "form-hero-module--input--udEn+";
export var label = "form-hero-module--label--JwVV1";
export var successText = "form-hero-module--success-text---5HJS";
export var text = "form-hero-module--text--VnJ+Z";
export var textarea = "form-hero-module--textarea--4IzV+";
export var title = "form-hero-module--title--7EHbw";