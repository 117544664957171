import React from 'react';

import { container, detail, image, text } from './contact-details.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

interface IContactDetailsProps {
    className?: string;
}

const ContactDetails: React.FC<IContactDetailsProps> = ({ className = '' }) => {
    return (
        <div className={`${className} ${container}`}>
            <div className={detail}>
                <div className={image}></div>
                <p className={text}>AlterPage Sp. z o.o.</p>
                <p className={text}>ul. Alzacka 16/2</p>
                <p className={text}>03-972 Warszawa</p>
                <p className={text}>NIP: 5213653177</p>
                <p className={text}>KRS: 0000475211</p>
                <p className={text}>REGON: 146803036</p>
            </div>
            <div className={detail}>
                <div className={image}>
                    <StaticImage src={'../../assets/images/piotr-avatar.png'} alt="" />
                </div>
                <p className={text}>Piotr Sobieszczak</p>
                <p className={text}>managing partner</p>
                <p className={text}>
                    telefon: <a href="tel:+48600337557">(+48) 600 337 557</a>
                </p>
                <p className={text}>
                    e-mail:{' '}
                    <a href="mailto:piotr.sobieszczak@alterpage.pl">
                        piotr.sobieszczak@alterpage.pl
                    </a>
                </p>
            </div>
            <div className={detail}>
                <div className={image}>
                    <StaticImage src={'../../assets/images/jakub-avatar.png'} alt="" />
                </div>
                <p className={text}>Jakub Puczyłowski</p>
                <p className={text}>managing partner</p>
                <p className={text}>
                    telefon: <a href="tel:+48606242774">(+48) 606 242 774</a>
                </p>
                <p className={text}>
                    e-mail:{' '}
                    <a href="mailto:jakub.puczylowski@alterpage.pl">
                        jakub.puczylowski@alterpage.pl{' '}
                    </a>
                </p>
            </div>
            <div className={detail}>
                <div className={image}></div>
                <p className={text}>
                    telefon: <a href="tel:+48577030905">(+48) 577 030 905</a>
                </p>
                <p className={text}>
                    e-mail: <a href="mailto:kontakt@alterpage.pl">kontakt@alterpage.pl</a>
                </p>
            </div>
        </div>
    );
};

export default ContactDetails;
