import React, { useState } from 'react';
import { motion } from 'framer-motion';

import {
    accordion,
    button,
    description,
    accordionTitle,
    accordionContent,
    circle,
    activeCircle,
} from './accordion-item.module.scss';

import Semicircle from './semicircle';
import Markdown from '../hoc/markdown';

export type TAccordionItemProps = {
    title: string;
    content: string;
};

const AccordionItem: React.FC<TAccordionItemProps> = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <li className={accordion}>
            <button className={button} onClick={() => setIsActive(!isActive)}>
                <Semicircle
                    className={`${circle} ${isActive && activeCircle}`}
                    orientation="left"
                    color="pink"
                />
                <div className={description}>
                    <div className={accordionTitle}>{title}</div>
                    <motion.div
                        style={{ overflow: 'hidden' }}
                        initial={{ height: 0 }}
                        animate={{ height: isActive ? 'auto' : 0 }}
                        transition={{ duration: 0.3 }}
                        className={accordionContent}
                    >
                        <Markdown>{content}</Markdown>
                    </motion.div>
                </div>
            </button>
        </li>
    );
};

export default AccordionItem;
