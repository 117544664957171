import React, { useState } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    container,
    innerContainer,
    title,
    titleBottom,
    logos,
    logo,
    semicircle,
    buttonCooperation,
} from './cooperation.module.scss';
import SectionWrapper from '../molecules/section-wrapper';

import { grid } from '../../styles/grid.module.scss';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { isMobileDevice } from '../../utils/is-mobile-device';

interface ICooperationProps {
    className?: string;
}

const Cooperation: React.FC<ICooperationProps> = ({ className = '' }) => {
    const { t } = useI18next();

    const [isActive, setIsActive] = useState(false);

    const isMobile = isMobileDevice();

    const data = useStaticQuery(graphql`
        query MyQuery {
            allFile(filter: { relativeDirectory: { eq: "cooperation" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(width: 240, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    `);

    return (
        <SectionWrapper
            color={'purple'}
            orientation={'left'}
            className={`${className} ${grid} ${container}`}
            semiCircleClassName={semicircle}
            scrollToSection="faq"
        >
            <div className={`${innerContainer}`} id="cooperation">
                <h4 className={title}>{t('lp1.cooperation.title.top')}</h4>

                <div className={logos}>
                    {isMobile &&
                        [...Array(isActive ? 20 : 4)].map((x, i) => {
                            const image = getImage(data.allFile.edges[i].node);

                            return (
                                <div className={logo} key={i}>
                                    {image && <GatsbyImage alt={''} image={image} />}
                                </div>
                            );
                        })}

                    {!isMobile &&
                        [...Array(20)].map((x, i) => {
                            const image = getImage(data.allFile.edges[i].node);

                            return (
                                <div className={logo} key={i}>
                                    {image && <GatsbyImage alt={''} image={image} />}
                                </div>
                            );
                        })}
                </div>

                {!isActive && isMobile && (
                    <button onClick={() => setIsActive(!isActive)} className={buttonCooperation}>
                        <span>zobacz więcej</span>
                        <span>zobacz więcej</span>
                    </button>
                )}
                <h4 className={titleBottom}>{t('lp1.cooperation.title.bottom')}</h4>
            </div>
        </SectionWrapper>
    );
};

export default Cooperation;
